<template>
  <div class="KnowledgeDetails-wrap">
    <div class="row">
      <div class="col-xxl-4 col-xl-4 col-md-4">
        <div class="mb-3">
          <search-filter @searchMessageList="searchMessageList" />
        </div>
        <div class="KnowledgeDetails-card pb-4">
          <div class="know-center-list">
            <ul>
              <li
                v-for="k in rows.records"
                :key="k.id"
                :class="listClass(k.id)"
                @click="showKnowledgeData(k)"
              >
                <knowledge-center-list :list="k" />
              </li>
            </ul>
          </div>
          <div  class="text-center mt-4" v-if="checkRowLength()">
            No result found
          </div>
          <div class="pagination-container">
            <pagination :rows.sync="rows" :showPageRecord="false" :filters="filterQuery" />
          </div>
        </div>
      </div>
      <div class="col-xxl-8 col-xl-8 col-md-8" v-if="showKnowledge">
        <knowledge-center-content :content="showKnowledge" />
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import KnowledgeCenterList from "@/views/KnowledgeCenter/partials/KnowledgeCenterListRep";
import KnowledgeCenterContent from "@/views/KnowledgeCenter/partials/KnowledgeCenterContentRep";
import Pagination from "../../components/ui/Pagination.vue";
import SearchFilter from "./partials/KnowledgeSearchFilter.vue";
export default {
  name: "KnowledgeCenterFromRep",
  components: {
    SearchFilter,
    KnowledgeCenterList,
    KnowledgeCenterContent,
    Pagination
},
  data: () => ({
    knowledges: [],
    rows: [],
    showKnowledge: null,
    currentItemId: null,
    breadcrumbItems: [{ title: "Knowledge Center", active: true }],
  }),
  methods: {
    listClass(id) {
      let activeClass = this.currentItemId == id ? "active" : "";
      return `knowledge-list ${activeClass}`;
    },
    searchMessageList (data) {
      let app = this
      app.searchQuery = data
      app.getKnowledges(app.searchQuery)
    },
    showKnowledgeData(row) {
      this.showKnowledge = row;
      this.currentItemId = row.id;
    },
    getKnowledges(searchQuery) {
      // let url = this.$endpoint.KNOWLEDGE_BASE_API_URL;
      let url = 'v2/knowledge-feed/list?user_related_feed=1'
      if (searchQuery) {
        const encodedQuery = encodeURIComponent(searchQuery);
        url += `&query=${encodedQuery}`;
      }
      let loader = this.$loading.show();
      axios
        .get(url)
        .then((res) => {
          this.rows = res.data;
          this.knowledges = res.data.records;
          this.showKnowledge = this.knowledges[0];
          this.currentItemId = this.showKnowledge.id;
        })
        .catch(() => {
          this.knowledges = [];
          this.showKnowledge = null;
          this.currentItemId = null;
        })
        .finally(() => {
          loader.hide();
        });
    },
    checkRowLength () {
      if (this.knowledges.length < 1) {
        return true
      } else {
        return false
      }
    }
  },
  created() {
    this.getKnowledges();
  },
  watch: {
    rows: function (newData, oldData) {
      if (newData != oldData) {
        this.showKnowledge =
            newData.records.length > 0 ? newData.records[0] : null;
        this.currentItemId = this.showKnowledge.id;
      }
    }
  }
};
</script>
<style src="@/assets/css/knowledge.css"></style>
<style scoped>
.table-pagination .b-pagination {
  justify-content: center !important;
}
.pagination-container {
  position: relative;
  top: 50px;
}
</style>
