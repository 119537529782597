var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "page-wrapper know-center-wrap knowledge-center-wrap"
  }, [_c('div', {
    staticClass: "container-fluid"
  }, [_c('breadcrumb', {
    attrs: {
      "items": _vm.breadcrumbItems
    }
  }), _vm._m(0), _c('b-card', {
    attrs: {
      "no-body": ""
    }
  }, [_c('b-tabs', {
    attrs: {
      "card": ""
    },
    model: {
      value: _vm.tabIndex,
      callback: function callback($$v) {
        _vm.tabIndex = $$v;
      },
      expression: "tabIndex"
    }
  }, [_c('b-tab', {
    attrs: {
      "title": "From Admin"
    },
    on: {
      "click": function click($event) {
        _vm.tabIndex = 0;
      }
    }
  }, [_c('b-card-text', [_c('KnowledgeFromAdminVue')], 1)], 1), _c('b-tab', {
    attrs: {
      "title": "From Rep"
    },
    on: {
      "click": function click($event) {
        _vm.tabIndex = 1;
      }
    }
  }, [_c('b-card-text', [_c('KnowledgeFromRepVue')], 1)], 1)], 1)], 1)], 1)]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "page-title"
  }, [_c('h1', [_vm._v("Knowledge Center")])]);
}]

export { render, staticRenderFns }