var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('a', [_c('div', {
    staticClass: "dashknw-icon"
  }, [_c('img', {
    attrs: {
      "src": _vm.image,
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "dashknw-content"
  }, [_c('h3', [_vm._v(_vm._s(_vm._f("truncate")(_vm.list.title, 25)))]), _c('span', {
    staticClass: "msg-date"
  }, [_vm._v(_vm._s(_vm.list.published_date))])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }