var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "email-tbl h-100"
  }, [_c('div', {
    staticClass: "search-wrap"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.searchQuery,
      expression: "searchQuery"
    }],
    staticClass: "search-input",
    attrs: {
      "placeholder": "Search "
    },
    domProps: {
      "value": _vm.searchQuery
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }
        _vm.searchQuery = $event.target.value;
      }
    }
  }), _c('button', {
    on: {
      "click": _vm.searchMessageList
    }
  }, [_c('i', {
    staticClass: "fa fa-search"
  })])])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }