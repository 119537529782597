<template>
      <div class="KnowledgeDetails-wrap">
        <div class="row">
          <div class="col-xxl-4 col-xl-4 col-md-4">
            <div class="mb-3">
              <search-filter @searchMessageList="searchMessageList" />
            </div>
            <div class="KnowledgeDetails-card pb-4">
              <div class="know-center-list">
                <ul>
                  <li
                    v-for="k in rows.data"
                    :key="k.id"
                    :class="listClass(k.id)"
                    @click="showKnowledgeData(k)"
                  >
                    <knowledge-center-list :list="k" />
                  </li>
                </ul>
              </div>
              <div  class="text-center mt-4" v-if="checkRowLength()">
                No result found
              </div>
            </div>
            <div>
              <admin-pagination :rows.sync="rows" :showPageRecord="true"  @getAdminData="getAdminData"/>
            </div>
          </div>
          <div class="col-xxl-8 col-xl-8 col-md-8" v-if="showKnowledge">
            <knowledge-center-content :content="showKnowledge" />
          </div>
        </div>
      </div>
</template>
<script>
/*eslint-disable*/
import axios from "axios";
import KnowledgeCenterList from "@/views/KnowledgeCenter/partials/KnowledgeCenterList";
import KnowledgeCenterContent from "@/views/KnowledgeCenter/partials/KnowledgeCenterContent";
import SearchFilter from "./partials/KnowledgeSearchFilter.vue";
import AdminPagination from "./partials/AdminPagination.vue";
export default {
  name: "KnowledgeCenterFromAdmin",
  components: {
    KnowledgeCenterList,
    KnowledgeCenterContent,
    SearchFilter,
    AdminPagination
  },
  data: () => ({
    knowledges: [],
    rows: [],
    showKnowledge: null,
    currentItemId: null,
    searchQuery: null,
    filterQuery: {
      "publication_party": [
        "Reps"
      ]
    }
  }),
  methods: {
    getAdminData (rows) {
      this.showKnowledge = rows.data[0];
      this.currentItemId = rows.data[0].id
    },
    listClass(id) {
      let activeClass = this.currentItemId == id ? "active" : "";
      return `knowledge-list ${activeClass}`;
    },
    searchMessageList (data) {
      let app = this
      app.searchQuery = data
      app.getKnowledges(app.searchQuery)
    },
    showKnowledgeData(row) {
      this.showKnowledge = row;
      this.currentItemId = row.id
    },
    getKnowledges(searchQuery) {
      let url = `${this.$endpoint.KNOWLEDGE_BASE_API_URL}`;
      let params = {
        publication_party: ["Reps"],
        agent_id: this.$store.getters.repId,
      };
      if (searchQuery) {
        const encodedQuery = encodeURIComponent(searchQuery);
        url += `?title_search=${encodedQuery}`;
      }

      let loader = this.$loading.show();
      axios
        .get(url, {params})
        .then((res) => {
          this.rows = res.data;
          this.knowledges = res.data.data;
          this.showKnowledge = this.knowledges[0];
          this.currentItemId = this.showKnowledge.id
        })
        .catch(() => {
          this.knowledges = [];
          this.rows= []
          this.showKnowledge = null;
          this.currentItemId = null
        })
        .finally(() => {
          loader.hide();
          if (this.knowledges.length < 1) {
           /* this.$router.push({ name: "Dashboard" }); */
          }
        });
    },
    checkRowLength () {
      if (this.knowledges.length < 1) {
        return true
      } else {
        return false
      }
    }
  },
  created() {
    this.getKnowledges();
  },
};
</script>
<style src="@/assets/css/knowledge.css"></style>
