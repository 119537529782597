<template>
  <a>
    <div class="dashknw-icon">
      <img :src="image" alt="" />
    </div>
    <div class="dashknw-content">
      <h3>{{list.title  | truncate(25)}}</h3>
      <span class="msg-date">{{list.published_date }}</span>
    </div>
  </a>
</template>
<script>
import dummyImage from "@/assets/images/user.svg";
export default {
  name: "KnowledgerCenterList",
  props:{
      list:{
          required:true
      }
  },
    data: () => ({
    image: dummyImage,
  }),
};
</script>