<template>
   <div class="KnowledgeDetails-Formcard">

   <div class="rcv-msgcontent">
          <div class="rcv-msg-sender">
            <div class="rcv-snder-icon">
             <img :src="image" alt="" class="msg-userIcon" />
              <div class="rcv-sender-details">
                <h6><h6>{{ content?.created_user?.rep_full_name ? content?.created_user?.rep_full_name : 'ADMIN' }}</h6></h6>
                <p>
                  <i class="far fa-calendar-alt"></i>
                  {{
                    content.published_date
                      | date_format("YYYY-MM-DD", "MM/DD/YYYY", content.published_date)
                  }}
                </p>
              </div>
            </div>
          </div>
          <h5>Title: {{ content.title }}</h5>
          <hr />
          <div class="ql-snow">
            <div class="ql-editor">
              <p v-html="content.description" />
            </div>
          </div>
       <div v-if="content.file && content.file.length > 0">
        <h6>Attachments:</h6>
        <ul style="list-style: none">
          <li v-for="file in content.file" :key="file.id">
            <a :href="file.path" target="__blank" download>
              <i class="fa fa-file"></i>
              {{ file.filename }}
            </a>
          </li>
        </ul>
      </div>
        </div>
  </div>
</template>
<script>

import dummyImage from "@/assets/images/user.svg";
import endpoint from "@/resource/EndPoints/endpoints";
export default {
  name: "KnowledgerCenterContent",
  props: {
    content: {
      required: true,
    },
  },
   computed: {
    image() {
      
      return dummyImage;
    },
    senderName() {
      return this.content && this.content.created_user ? this.content.created_user.rep_full_name : 'ADMIN'
    }
  },
  data: () => ({
    knowledgeBaseDownloadUrl: endpoint.KNOWLEDGE_BASE_DOWNLOAD_URL,
  }),
};
</script>


<style scoped>
.rcv-msgcontent .rcv-msg-sender .rcv-snder-icon img {
    max-width: 40px;
    display: inline-block;
    vertical-align: top;
}
.rcv-msgcontent .rcv-msg-sender .rcv-snder-icon .rcv-sender-details {
    display: inline-block;
    margin-left: 15px;
}
</style>
