<template>
  <div class="page-wrapper know-center-wrap knowledge-center-wrap">
    <div class="container-fluid">
      <breadcrumb :items="breadcrumbItems" />
      <div class="page-title">
        <h1>Knowledge Center</h1>
      </div>
      <b-card no-body>
        <b-tabs card v-model="tabIndex">
          <b-tab title="From Admin" @click="tabIndex = 0">
            <b-card-text>
                <KnowledgeFromAdminVue />
            </b-card-text>
          </b-tab>

            <b-tab title="From Rep" @click="tabIndex = 1">
            <b-card-text>
               <KnowledgeFromRepVue />
            </b-card-text>
          </b-tab>
        </b-tabs>
      </b-card>
    </div>
  </div>
</template>
<script>
/*eslint-disable*/
import KnowledgeFromAdminVue from './KnowledgeFromAdmin.vue';
import KnowledgeFromRepVue from './KnowledgeFromRep.vue';
export default {
  name: "KnowledgeCenter",
  components: {
    KnowledgeFromAdminVue,
    KnowledgeFromRepVue
  },
  data: () => ({
    knowledges: [],
    showKnowledge: null,
    currentItemId: null,
    breadcrumbItems: [{ title: "Knowledge Center", active: true }],
    tabIndex: 0,
  }),

};
</script>
<style src="@/assets/css/knowledge.css"></style>
