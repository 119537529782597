var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "KnowledgeDetails-wrap"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-xxl-4 col-xl-4 col-md-4"
  }, [_c('div', {
    staticClass: "mb-3"
  }, [_c('search-filter', {
    on: {
      "searchMessageList": _vm.searchMessageList
    }
  })], 1), _c('div', {
    staticClass: "KnowledgeDetails-card pb-4"
  }, [_c('div', {
    staticClass: "know-center-list"
  }, [_c('ul', _vm._l(_vm.rows.data, function (k) {
    return _c('li', {
      key: k.id,
      class: _vm.listClass(k.id),
      on: {
        "click": function click($event) {
          return _vm.showKnowledgeData(k);
        }
      }
    }, [_c('knowledge-center-list', {
      attrs: {
        "list": k
      }
    })], 1);
  }), 0)]), _vm.checkRowLength() ? _c('div', {
    staticClass: "text-center mt-4"
  }, [_vm._v(" No result found ")]) : _vm._e()]), _c('div', [_c('admin-pagination', {
    attrs: {
      "rows": _vm.rows,
      "showPageRecord": true
    },
    on: {
      "update:rows": function updateRows($event) {
        _vm.rows = $event;
      },
      "getAdminData": _vm.getAdminData
    }
  })], 1)]), _vm.showKnowledge ? _c('div', {
    staticClass: "col-xxl-8 col-xl-8 col-md-8"
  }, [_c('knowledge-center-content', {
    attrs: {
      "content": _vm.showKnowledge
    }
  })], 1) : _vm._e()])]);
}
var staticRenderFns = []

export { render, staticRenderFns }