var render = function () {
  var _vm$content, _vm$content$created_u, _vm$content2, _vm$content2$created_;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "KnowledgeDetails-Formcard"
  }, [_c('div', {
    staticClass: "rcv-msgcontent"
  }, [_c('div', {
    staticClass: "rcv-msg-sender"
  }, [_c('div', {
    staticClass: "rcv-snder-icon"
  }, [_c('img', {
    staticClass: "msg-userIcon",
    attrs: {
      "src": _vm.image,
      "alt": ""
    }
  }), _c('div', {
    staticClass: "rcv-sender-details"
  }, [_c('h6', [_c('h6', [_vm._v(_vm._s((_vm$content = _vm.content) !== null && _vm$content !== void 0 && (_vm$content$created_u = _vm$content.created_user) !== null && _vm$content$created_u !== void 0 && _vm$content$created_u.rep_full_name ? (_vm$content2 = _vm.content) === null || _vm$content2 === void 0 ? void 0 : (_vm$content2$created_ = _vm$content2.created_user) === null || _vm$content2$created_ === void 0 ? void 0 : _vm$content2$created_.rep_full_name : 'ADMIN'))])]), _c('p', [_c('i', {
    staticClass: "far fa-calendar-alt"
  }), _vm._v(" " + _vm._s(_vm._f("date_format")(_vm.content.published_date, "YYYY-MM-DD", "MM/DD/YYYY", _vm.content.published_date)) + " ")])])])]), _c('h5', [_vm._v("Title: " + _vm._s(_vm.content.title))]), _c('hr'), _c('div', {
    staticClass: "ql-snow"
  }, [_c('div', {
    staticClass: "ql-editor"
  }, [_c('p', {
    domProps: {
      "innerHTML": _vm._s(_vm.content.description)
    }
  })])]), _vm.content.file && _vm.content.file.length > 0 ? _c('div', [_c('h6', [_vm._v("Attachments:")]), _c('ul', {
    staticStyle: {
      "list-style": "none"
    }
  }, _vm._l(_vm.content.file, function (file) {
    return _c('li', {
      key: file.id
    }, [_c('a', {
      attrs: {
        "href": file.path,
        "target": "__blank",
        "download": ""
      }
    }, [_c('i', {
      staticClass: "fa fa-file"
    }), _vm._v(" " + _vm._s(file.filename) + " ")])]);
  }), 0)]) : _vm._e()])]);
}
var staticRenderFns = []

export { render, staticRenderFns }